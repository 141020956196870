import React from "react"
import {
  DoublePanelContainer,
  LeftPanelContainer,
  RightPanelContainer,
  PanelWrapper,
} from "./style"
import "utils/transitions.css"

const DoublePanel = ({
  className,
  leftColor,
  rightColor,
  leftContent,
  rightContent,
}) => {
  return (
    <DoublePanelContainer className={className}>
      <LeftPanelContainer leftColor={leftColor}>
        <PanelWrapper>{leftContent}</PanelWrapper>
      </LeftPanelContainer>
      <RightPanelContainer rightColor={rightColor}>
        <PanelWrapper>{rightContent}</PanelWrapper>
      </RightPanelContainer>
    </DoublePanelContainer>
  )
}

export default DoublePanel
