import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { HomePage } from "../components/HomePage"

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <HomePage/>
    </Layout>
  )
}

export default IndexPage
