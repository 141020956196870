import React from "react"
import { HomePageContainer, HeaderText } from "./style"
import { MainColumn } from "components/MainColumn"
import { DoublePanel } from "../DoublePanel"
import { ReactComponent as VICillustration } from "images/icons/illustration-vic-v01.svg"
import { Link } from "gatsby"
import { ReactComponent as Arrow } from "images/icons/arrow.svg"
import { ReactComponent as NSWillustration } from "images/icons/illustration-nsw-v01.svg"
import { useStaticQuery, graphql } from "gatsby"

import {
  LeftPanelContent,
  RightPanelContent,
  PanelTitle,
  TextWrapper,
} from "components/DoublePanel/style"
const HomePage = () => {
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        finefixerOptions {
          mainHomepageVicNsw {
            headerStatement
            nswpanel {
              heading
              description
            }
            vicpanel {
              heading
              description
            }
          }
        }
      }
    }
  `)

  const pageData = data.wpgraphql.finefixerOptions.mainHomepageVicNsw
  console.log("pageData:", pageData)
  const leftContent = (
    <LeftPanelContent>
      <a href="https://vic.finefixer.org.au">
        <PanelTitle>
          {pageData.vicpanel.heading}
          <Arrow />
        </PanelTitle>
      </a>
      <TextWrapper>
        <p>{pageData.vicpanel.description}</p>

        <VICillustration className="cover-svg vic-illustration" />
        <p className="small">A free legal service provided by</p>
        <Link to="https://www.mvls.org.au/">
          <p className="underline">Moonee Valley Legal Service</p>
        </Link>
      </TextWrapper>
    </LeftPanelContent>
  )

  const rightContent = (
    <RightPanelContent>
      <a href="https://nsw.finefixer.org.au">
        <PanelTitle>
          {pageData.nswpanel.heading}
          <Arrow />
        </PanelTitle>
      </a>
      <TextWrapper>
        <p>{pageData.nswpanel.description}</p>

        <NSWillustration className="cover-svg nsw-illustration" />

        <p className="small">A free legal service provided by</p>
        <Link to="https://www.legalaid.nsw.gov.au/">
          <p className="underline">Legal Aid New South Wales</p>
        </Link>
      </TextWrapper>
    </RightPanelContent>
  )
  return (
    <HomePageContainer>
      <MainColumn>
        <HeaderText>{pageData.headerStatement}</HeaderText>
      </MainColumn>
      <DoublePanel leftContent={leftContent} rightContent={rightContent} />
    </HomePageContainer>
  )
}

export default HomePage
