import styled from "styled-components"
import { device } from "breakpoints"

export const HomePageContainer = styled.div`
  min-height: calc(100vh - 90px - 63px);
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    min-height: calc(100vh - 120px - 100px);
  }
`

export const HeaderText = styled.p`
  font-size: 16px;
  line-height: 133%;
  margin-top: -15px;
  margin-bottom: 40px;

  @media ${device.tablet} {
    margin-top: -30px;
  }
  @media ${device.desktop} {
    font-size: 24px;
    max-width: 755px;
  }
`
